import "@Styles/Shared/ConsumerGeneratedContent/Cgc/contentList_sm.less";
import "@Styles/Shared/ConsumerGeneratedContent/CgcRedesign/contentListBase_md.less";
import "@Styles/Shared/ConsumerGeneratedContent/CgcRedesign/contentListBase_sm.less";
import "@Styles/Shared/SearchNx/contentList_sm.less";
import "@Styles/Shared/ConsumerGeneratedContent/CgcRedesign/PB/contentListBase_sm.less";
import "@Styles/Shared/ConsumerGeneratedContent/Cgc/contentList_md.less";
import "@Styles/Shared/SearchNx/contentList_md.less";
import "@Styles/Shared/SearchNx/contentList_lg.less";
import { cookie } from '@Shared/Common';
import { FAVORITE_COOKIE_NAME } from './_constants';
import { toPascalCase } from './_util';
import { getContentListResults } from './_api';
import carouselList from './ListTemplates/carouselList';
import categoryTile from './ListTemplates/categoryTile';
export default {
  components: {
    carouselList: carouselList,
    categoryTile: categoryTile
  },
  data: function data() {
    return {
      config: {},
      listSettings: {},
      results: {},
      listComponent: '',
      isLoading: true,
      isAnonymous: true,
      isAnonWithFav: true
    };
  },
  methods: {
    init: function init(config) {
      var _this = this;

      if (config) {
        this.config = config;
        this.listSettings = config.contentListSettings;
        this.listComponent = this.listSettings.listType;
        this.isAnonymous = config.isAnonymous;
        this.isAnonWithFav = config.isAnonymousUserWithFavorites;

        if (this.config.serverSearchResponse.count > 0) {
          this.results = config.serverSearchResponse.results;
          this.$_checkSavedItemCookie();
          this.isLoading = false;
        } else {
          var searchParams = {
            contentId: this.listSettings.id,
            listDataSourceSettingsIds: this.config.listDataSourceSettingsIds,
            pageIndex: 1,
            sortOption: {
              sortName: this.listSettings.defaultSortBy ? this.listSettings.defaultSortBy.name : null,
              sortKey: this.listSettings.defaultSortBy ? this.listSettings.defaultSortBy.key : null,
              sortDirection: this.listSettings.defaultSortBy ? this.listSettings.defaultSortBy.direction : null
            },
            orProperties: this.$_getOrProperties(),
            getAllPages: false,
            requestMetadata: this.config.requestMetadata
          };
          getContentListResults(searchParams).then(function (response) {
            if (response.count > 0) {
              _this.results = response.results;

              _this.$_checkSavedItemCookie();

              _this.isLoading = false;
            }
          });
        }
      }
    },
    contentItemClickEvent: function contentItemClickEvent(contentItem) {
      GeneralMills.Analytics.raiseEvent({
        EventType: 'ContentResult',
        ContentId: contentItem.id,
        ContentTitle: contentItem.title,
        ContentType: toPascalCase(contentItem.contentType),
        RecordType: toPascalCase(contentItem.contentType),
        ContentListName: this.listSettings.title,
        Language: contentItem.language,
        TemplateName: this.listSettings.listType
      });
    },
    $_checkSavedItemCookie: function $_checkSavedItemCookie() {
      if (!this.isAnonymous) {
        var favoriteCookie = cookie.getCookie(FAVORITE_COOKIE_NAME);

        if (favoriteCookie) {
          var favoritedItemIndex = this.results.findIndex(function (r) {
            return r.id === favoriteCookie;
          });

          if (favoritedItemIndex !== -1) {
            this.results[favoritedItemIndex].cookieAddFavorite = true;
            cookie.removeCookie(FAVORITE_COOKIE_NAME);
          }
        }
      }
    },
    $_getOrProperties: function $_getOrProperties() {
      var orContentFilters = this.listSettings.defaultContentTypeFilters && this.listSettings.defaultContentTypeFilters.length > 0 ? this.listSettings.defaultContentTypeFilters : this.listSettings.contentTypeFilterValues;
      return orContentFilters.map(function (contentFilter) {
        return {
          propertyName: "Content Type",
          propertyValue: contentFilter.name
        };
      });
    }
  }
};
import "@Styles/Shared/SimpleNav/targetedNav_sm.less";
import "@Styles/Shared/SimpleNav/PB/targetedNav_sm.less";
import "@Styles/Shared/SimpleNav/targetedNav_lg.less";
import "@Styles/Shared/SimpleNav/PB/targetedNav_lg.less";
export default (function (config, elementId) {
  return _initializeNavigation(elementId);
});

var _initializeNavigation = function _initializeNavigation(elementId) {
  var vm = {},
      isNavOpen = false,
      OPENED_CLASS = "opened",
      L1_POSITION = "Main Navigation";
  var rootElement = document.body;
  var navElement = rootElement.querySelector("#" + elementId);
  var toggleElement = rootElement.querySelector(".simpleNavToggle");
  var navChildLookup = navElement.querySelectorAll(".levelOneItems > li.withChildren");
  var navChildren = [];

  for (var i = 0; i < navChildLookup.length; i++) {
    navChildren.push(_initializeChild(navChildLookup[i]));
  }

  vm.NavOpen = function () {
    isNavOpen = true;
    navElement.classList.add(OPENED_CLASS);
    toggleElement.classList.add(OPENED_CLASS);
  };

  vm.NavClose = function () {
    isNavOpen = false;
    navElement.classList.remove(OPENED_CLASS);
    toggleElement.classList.remove(OPENED_CLASS);
    navChildren.forEach(function (c) {
      c.MenuCollapse();
    });
  };

  vm.NavToggleClick = function (eventArgs) {
    // Toggle clicks should not propogate events up. This avoids close/open actions from occurring unexpectedly.            
    eventArgs.stopPropagation();

    if (isNavOpen) {
      vm.NavClose();
    } else {
      vm.NavOpen();
    }
  };

  toggleElement.addEventListener("click", vm.NavToggleClick);

  vm.MyAccountToggleClick = function (eventArgs) {
    // Toggle clicks should not propogate events up. This avoids close/open actions from occurring unexpectedly.            
    eventArgs.stopPropagation();

    if (isMyAccountOpen) {
      vm.MyAccountClose();
    } else {
      vm.MyAccountOpen();
      vm.NavClose();
    }
  }; // Click events outside of navigation should close the nav


  rootElement.addEventListener("click", function () {
    vm.NavClose();
  });

  vm.NavigationLinkClick = function (eventArgs) {
    // analytics event for each link click
    var navEvent = {
      EventType: "NavigationClick",
      LinkPosition: eventArgs.target.getAttribute("data-position") || L1_POSITION,
      LinkUrl: eventArgs.target.href,
      LinkTitle: eventArgs.target.textContent
    };
    GeneralMills.Analytics && GeneralMills.Analytics.raiseEvent(navEvent);
  };

  var navLinks = navElement.querySelectorAll("a");

  for (var _i = 0; _i < navLinks.length; _i++) {
    navLinks[_i].addEventListener("click", vm.NavigationLinkClick);
  }

  return vm;
};

var _initializeChild = function _initializeChild(navChildElement) {
  var vm = {},
      isOpen = false,
      MENU_EXPANDED_CLASS = "expanded",
      // Used for hovering in desktop view
  COLLAPSE_MENU_EVENT = "collapse_menu_event";
  if (!navChildElement || navChildElement.length === 0) navChildElement = document.createElement("ul");

  vm.MenuExpand = function () {
    isOpen = true;
    navChildElement.classList.add(MENU_EXPANDED_CLASS); // Set aria-expanded attribute on relevant element based on screen size

    if (window.innerWidth >= 1000) {
      var mainNavElementAnchor = navChildElement.querySelectorAll("a.mainLink");

      if (mainNavElementAnchor && mainNavElementAnchor[0]) {
        mainNavElementAnchor[0].setAttribute("aria-expanded", true);
      }
    } else {
      navChildElement.setAttribute("aria-expanded", true);
    }
  };

  vm.MenuCollapse = function () {
    isOpen = false;
    navChildElement.classList.remove(MENU_EXPANDED_CLASS); // Set aria-expanded attribute on relevant element based on screen size

    if (window.innerWidth >= 1000) {
      var mainNavElementAnchor = navChildElement.querySelectorAll("a.mainLink");

      if (mainNavElementAnchor && mainNavElementAnchor[0]) {
        mainNavElementAnchor[0].setAttribute("aria-expanded", false);
      }
    } else {
      navChildElement.setAttribute("aria-expanded", false);
    }
  };

  vm.OnToggleDropdown = function (eventArgs) {
    eventArgs.stopPropagation();

    if (isOpen) {
      vm.MenuCollapse();
    } else {
      var event = document.createEvent('Event');
      event.initEvent(COLLAPSE_MENU_EVENT, true, true);
      document.dispatchEvent(event);
      vm.MenuExpand();
    }
  }; // Listen to close menu event


  document.addEventListener(COLLAPSE_MENU_EVENT, vm.MenuCollapse); // Links should not propogate events up. This avoids close/open actions from occurring unexpectedly.

  var navElementAnchors = navChildElement.querySelectorAll("a");

  for (var i = 0; i < navElementAnchors.length; i++) {
    navElementAnchors[i].addEventListener("click", function (eventArgs) {
      eventArgs.stopPropagation();
    });
  }

  var mainNavElementAnchors = navChildElement.querySelectorAll("a.mainLink");

  for (var _i2 = 0; _i2 < mainNavElementAnchors.length; _i2++) {
    // Set aria attributes for level one items with children
    if (navChildElement.querySelectorAll(".subNavDisplay") && window.innerWidth >= 1000) {
      // Set on main navigation links on desktop, since that acts as the expanding button on desktop
      mainNavElementAnchors[_i2].setAttribute("role", "button");

      mainNavElementAnchors[_i2].setAttribute("aria-controls", "subNav_".concat(_i2));

      mainNavElementAnchors[_i2].setAttribute("aria-expanded", false);
    } else if (navChildElement.querySelectorAll(".subNavDisplay") && window.innerWidth < 1000) {
      // Set on list item on mobile, since that acts as the expanding button on mobile
      navChildElement.setAttribute("role", "button");
      navChildElement.setAttribute("aria-controls", "subNav_".concat(_i2));
      navChildElement.setAttribute("aria-expanded", false);
    } // Disable main navigation links only on desktop


    mainNavElementAnchors[_i2].addEventListener("click", function (eventArgs) {
      var mobileMenuOpened = document.querySelector("nav.opened") == null ? false : true;

      if (!mobileMenuOpened) {
        eventArgs.preventDefault();
        vm.OnToggleDropdown(eventArgs); // ensure general click handler does not fire to trigger analytics click event

        event.stopImmediatePropagation();
      }
    });
  } // For mobile menu expand/collapse sub menu icon


  navChildElement.addEventListener("click", vm.OnToggleDropdown);
  return vm;
};
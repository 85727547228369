import "@Styles/Shared/ConsumerGeneratedContent/ratingAndReviewCounts_sm.less";
import "@Styles/Shared/ConsumerGeneratedContent/PB/ratingAndReviewCounts_sm.less";
export default (function (config, elementId) {
  var setupObserver = function setupObserver(sectionName) {
    var rrSection = document.getElementById('cgcRatingAndReview');
    var qaSection = document.getElementById('cgcQuestionAndAnswer');

    if (sectionName && rrSection && qaSection && window.MutationObserver && !rrSection.querySelector('.cgcContentList')) {
      // check for changes on R&R to detect when it's finished loading
      var observer = new MutationObserver(function (mutations, observer) {
        for (var i = 0; i < mutations.length; i++) {
          var mutation = mutations[i];

          if (mutation.type === 'childList' && mutation.target.parentElement.id === 'cgcRatingAndReview' && mutation.target.className.indexOf('cgcContentList') > -1) {
            // scroll down to desired section once CGC is finished loading
            var section = document.getElementById(sectionName);
            section && section.scrollIntoView();
            observer.disconnect();
            return;
          }
        }
      });
      observer.observe(rrSection.parentNode.parentNode, {
        subtree: true,
        childList: true
      });
    }
  };

  if (window.location.hash === '#cgcRatingAndReview') {
    setupObserver('cgcRatingAndReview');
    window.addEventListener('load', function () {
      // necessary to trigger initial loading of CGC once page load is complete, which triggers the observer
      document.getElementById('cgcRatingAndReview').scrollIntoView();
    });
  } else if (window.location.hash === '#cgcQuestionAndAnswer') {
    setupObserver('cgcQuestionAndAnswer');
    window.addEventListener('load', function () {
      // necessary to trigger initial loading of CGC once page load is complete, which triggers the observer
      document.getElementById('cgcRatingAndReview').scrollIntoView();
    });
  } else {
    // Add event listeners to the CGC section links to wait to setup the observer until clicked
    var rrCountsContainer = document.querySelector('.ratingCountContainer a');
    rrCountsContainer && rrCountsContainer.addEventListener('click', setupObserver.bind(event, 'cgcRatingAndReview'));
    var qaCountsContainer = document.querySelector('.questionCountContainer a');
    qaCountsContainer && qaCountsContainer.addEventListener('click', setupObserver.bind(event, 'cgcQuestionAndAnswer'));
  }
});
import "@Styles/Controls/ContentBuilder/verticalVideoList_sm.less";
import "@Styles/Controls/ContentBuilder/PB/verticalVideoList_sm.less";
import "@Styles/Controls/ContentBuilder/verticalVideoList_md.less";
import "@Styles/Controls/ContentBuilder/verticalVideoList_lg.less";
var carouselBase = function carouselBase() {
  return import(
  /* webpackChunkName: 'carouselBase' */
  '@Shared/Common/Scripts/Components/carouselBase');
};

import { hasPrivacyOptOut } from './_util';
export default {
  components: {
    'carouselBase': carouselBase
  },
  data: function data() {
    return {
      settings: {},
      videoItems: [],
      visibleItemsCount: 3,
      componentsLoaded: false
    };
  },
  methods: {
    init: function init(config) {
      if (config) {
        this.settings = config.settings;
        this.videoItems = config.videoItems;
        this.componentsLoaded = true;
      }
    },
    onPlayButtonClick: function onPlayButtonClick(videoIndex) {
      var _this = this;

      this.videoItems[videoIndex].showPlayButton = false; // Check if user has cookie conset

      if (hasPrivacyOptOut()) {
        this.videoItems[videoIndex].subComponentType = 'privacyMessage';
        this.videoItems[videoIndex].subComponentContent = this.settings.privacyScreenContent;
      } else {
        this.videoItems[videoIndex].subComponentType = 'videoPlayer';
        this.videoItems[videoIndex].subComponentContent = this.videoItems[videoIndex].embedCode; // Send analytics event

        GeneralMills.Analytics && GeneralMills.Analytics.raiseEvent({
          EventType: 'VideoStart',
          ContentListName: this.settings.headingText,
          TemplateName: 'VideoListItem',
          VideoId: this.videoItems[videoIndex].videoId,
          VideoTitle: this.videoItems[videoIndex].videoTitle,
          VideoProvider: this.videoItems[videoIndex].provider
        }); // Set focus to iframe

        setTimeout(function () {
          var iframe = document.getElementById('frame' + _this.videoItems[videoIndex].videoId);
          if (iframe) iframe.focus();
        }, 250);
      }
    },
    onListCtaClick: function onListCtaClick() {
      GeneralMills.Analytics && GeneralMills.Analytics.raiseEvent({
        EventType: 'ContentResult',
        ContentListName: this.settings.headingText,
        TemplateName: 'VideoList',
        LinkUrl: this.settings.ctaButtonLink
      });
    },
    onItemCtaClick: function onItemCtaClick(videoIndex) {
      GeneralMills.Analytics && GeneralMills.Analytics.raiseEvent({
        EventType: 'ContentResult',
        ContentListName: this.videoItems[videoIndex].videoTitle,
        TemplateName: 'VideoListItem',
        LinkUrl: this.videoItems[videoIndex].ctaButtonLink
      });
    }
  }
};